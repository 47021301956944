<template>
  <!-- begin:: Footer -->
  <div
    class="footer kt-grid__item bgi-position-center-center bgi-size-cover bgi-no-cover"
    id="kt_footer"
    style="background-image: url('../media/bg/bg-2.jpg');"
  >
    <!--begin::Container-->
    <div class="separator separator-solid opacity-7"></div>
    <!--end::Container-->
    <!--begin::Container-->
    <div class="container py-8">
      <div
        class="d-flex align-items-center justify-content-between flex-lg-row flex-column"
      >
        <!--begin::Copyright-->
        <div class="d-flex align-items-center order-lg-1 order-2">
          <img
            alt="Logo"
            src="/../media/logos/vibhaalogo.png"
            class="logo-sticky max-h-35px"
          />
          <span class="text-muted font-weight-bold mx-2"
            >{{ new Date().getFullYear() }}©</span
          >
          <a
            href="https://vibhaa.in"
            target="_blank"
            class="text-primary text-hover-primary"
          >
            A Vibhaa Technologies Product
          </a>
        </div>
        <!--end::Copyright-->
        <!--begin::Nav-->
        <div class="d-flex align-items-center order-lg-2 order-1 mb-lg-0 mb-5">
          <a
            href="https://vibhaa.in/support/"
            target="_blank"
            class="text-white opacity-55 pl-0 text-hover-primary"
          >
            Tech Support
          </a>
          <!-- <a
            href="http://keenthemes.com/metronic"
            target="_blank"
            class="text-white opacity-55 px-6 text-hover-primary"
          >
            Team
          </a>
          <a
            href="http://keenthemes.com/metronic"
            target="_blank"
            class="text-white opacity-55 pr-0 text-hover-primary"
          >
            Contact
          </a> -->
        </div>
        <!--end::Nav-->
      </div>
    </div>
    <!--end::Container-->
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>
